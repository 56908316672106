<template>
  <!-- 交易记录详情 -->
  <div v-loading="loading" class="container">
    <!-- 标题 -->
    <div class="trade-title" @click="back">
      <span class="iconfont icon-a-fundmarkect-back" />
      <span>交易记录</span>
    </div>
    <div class="detail-total">
      <!-- 交易状态及订单号 -->
      <div class="trade-status flex">
        <div
          class="flex"
          :class="{
            'icon-err': findData.orderStatus == '4'
          }"
        >
          <span
            style="
              width: 24px;
              height: 24px;
              font-size: 24px;
              margin-right: 14px;
            "
            :class="{
              'el-icon-error': findData.orderStatus == '4',
              'el-icon-warning': findData.orderStatus == '2',
              'el-icon-success':
                findData.orderStatus == '1' || findData.orderStatus == '3'
            }"
          />
          <!-- {{ statusTitle }} -->
          <span v-if="findData.ofundType == '9'">{{ orderTypeDic[findData.ofundType] }}</span>
          <span v-else>{{ orderStatusDic[findData.orderStatus] }}</span>
        </div>
        <div>
          订单号:
          <span>#{{ findData.orderNo }}</span>
        </div>
      </div>

      <div class="trade-content">
        <!-- 下单流程 -->
        <el-steps :active="active" align-center style="padding: 0 50px">
          <!-- <el-step v-for="item in orderRecordsData" :key="item.id">
              <span
                slot="icon"
                class="icon el-icon-success icon-check"
                :class="{
                  'icon-err':findData.orderStatus=='4',
                  'icon-check':findData.orderStatus=='3',
                  'el-icon-error':findData.orderStatus=='4',
                  'el-icon-success':findData.orderStatus=='1'||findData.orderStatus=='2'||findData.orderStatus=='3',
                } "
              />
              <div slot="title" class="describe">
                <div class="subtitle1">{{ orderStatusDic[item.orderStatus] }}</div>
              </div>
              <div slot="title">
                <div class="date">{{ item.createTime }}</div>
              </div>
            </el-step>

            <el-step v-if="orderRecordsData.length==1" class="step2">
              <span
                slot="icon"
                class="icon icon-check"
                :class="{
                  'el-icon-warning':findData.orderStatus=='4',
                  'el-icon-success':findData.orderStatus=='1'||findData.orderStatus=='2'||findData.orderStatus=='3'
                }"
              />
              <div slot="title" class="describe deactive">
                <div class="subtitle1">{{ processTitle.process }}</div>
              </div>
              <div slot="title" class="date">
                <div>2021-07-27 11:16:02</div>
              </div>
            </el-step>
            <el-step v-if="orderRecordsData.length!==3" class="step3">
              <i
                slot="icon"
                class="icon"
                :class="{
                  'icon-err':findData.orderStatus=='4',
                  'icon-check':findData.orderStatus=='3',
                  'el-icon-error':findData.orderStatus=='4',
                  'el-icon-success':findData.orderStatus=='1'||findData.orderStatus=='2'||findData.orderStatus=='3',
                } "
              />
              <div slot="title" class="describe">
                <div
                  class="subtitle1"
                  :class="{'failed':findData.orderStatus=='4'}"
                >{{ processTitle.result }}</div>
              </div>
              <div slot="title" class="date">
                <div v-if="findData.orderStatus=='3'||findData.orderStatus=='4'">2021-07-27 11:16:02</div>
              </div>
          </el-step>-->
          <!-- 步骤一 -->
          <el-step class="step1">
            <span slot="icon" class="icon-main el-icon-success icon-check" />
            <div slot="title" class="describe">
              <div class="subtitle1">
                <!-- {{
                orderStatusDic[
                orderRecordsData[0] && orderRecordsData[0].orderStatus
                ]||'--'
                }} -->
                {{findData.orderStatus=='22'?'预约成功':findData.orderStatus=='20'?'待审核':findData.orderStatus=='21'?'待上传资料':'预约成功'}}
                
              </div>
            </div>
            <div slot="title">
              <div class="date">{{ findData.createTime}}</div>
            </div>
          </el-step>
          <!-- 步骤二 -->
          <el-step class="step2">
            <span
              slot="icon"
              class="icon-main icon-check"
              :class="{
                'el-icon-error':findData.orderStatus=='4',
                'el-icon-warning':findData.orderStatus=='2'||findData.orderStatus=='1',
                'el-icon-success': findData.orderStatus == '3'
              }"
            />
            <div slot="title" class="describe deactive">
              <div class="subtitle1">
                {{
                orderStatusDic[
                orderRecordsData[1] && orderRecordsData[1].orderStatus
                ] || '确认中'
                }}
              </div>
            </div>
            <div slot="title" class="date">
              <div
                v-if="findData.orderStatus !== '1'"
              >{{ findData.updateTime ||'--' }}</div>
            </div>
          </el-step>
          <!-- 步骤三 -->
          <el-step class="step3">
            <i
              slot="icon"
              class="icon-main"
              :class="{
                'icon-err': findData.orderStatus == '4',
                'icon-check': findData.orderStatus == '3',
                'el-icon-error': findData.orderStatus == '4',
                'el-icon-success': findData.orderStatus !== '4'
              }"
            />
            <div slot="title" class="describe">
              <!-- ofundType为9，订单为撤回状态 -->
             
              <div class="subtitle1" :class="{ failed: findData.orderStatus == '4' }">
                {{
                orderStatusDic[
                orderRecordsData[2] && orderRecordsData[2].orderStatus
                ] || findData.orderStatus == '22'?'确认份额':'确认份额'
                }}
              </div>
            </div>
            <div slot="title" class="date">
              <div
                v-if="
                  findData.orderStatus == '3' || findData.orderStatus == '4'
                "
              >{{ orderRecordsData[2] && orderRecordsData[2].createTime }}</div>
            </div>
          </el-step>
        </el-steps>
        <div class="info">
          <div class="smtital">交易基本信息</div>

          <div class="flex table-list">
            <div class="table-title">申请时间：</div>
            <div class="table-detail">{{ findData.createTime || '--' }}</div>
          </div>
          <div class="flex table-list">
            <div class="table-title">交易日期：</div>
            <div
              class="table-detail"
            >{{ (findData.createTime&&findData.createTime.slice(0,10)) || '--' }}</div>
          </div>
          <div class="flex table-list">
            <div class="table-title">交易类型：</div>
            <div class="table-detail">{{ orderTypeDic[findData.orderType] || '--' }}</div>
          </div>
        </div>
        <div class="smtital">产品信息</div>
        <div>
          <div class="flex table-list">
            <div class="table-title">基金简称：</div>
            <div class="table-detail">{{ findData.fundName || '--' }}</div>
          </div>
          <div class="flex table-list">
            <div class="table-title">收费方式：</div>
            <div class="table-detail">{{ shareTypeDic[findData.shareType] || '--' }}</div>
          </div>
          <div class="flex table-list">
            <div class="table-title">买入金额(元)：</div>
            <div class="table-detail">{{ findData.buyBalance || '--' }}</div>
          </div>
          <div class="flex table-list">
            <div class="table-title">确认份额(份)：</div>
            <div class="table-detail">{{ findData.buyShare || '--' }}</div>
          </div>
          <!-- <div class="flex table-list">
            <div class="table-title">成交净值：</div>
            <div class="table-detail">{{ '等待确认字段' }}</div>
          </div> -->
          <div class="flex table-list">
            <div class="table-title">手续费(元)：</div>
            <div class="table-detail">{{ findData.payFee || '--' }}</div>
          </div>
        </div>

        <div class="smtital">支付信息</div>
        <div>
          <div class="flex table-list">
            <div class="table-title">买入方式：</div>
            <!-- M,P银行卡，K线下大额转账 -->
            <!-- <div class="table-detail">{{ findData.capitalMode?buyWay(findData.capitalMode):'--' }}</div> -->
            <div class="table-detail">{{ buyMode }}</div>
          </div>
          <div class="flex table-list">
            <div class="table-title">交易账户：</div>
            <div class="table-detail">{{ accs || '--' }}</div>
          </div>
          <div class="flex table-list">
            <div class="table-title">交易状态：</div>
            <div class="table-detail">{{ orderStatusDic[findData.orderStatus] || '--' }}</div>
          </div>
        </div>
        <div class="tips flex">
          <div class="icon">
            <span class="iconfont icon-mine-tip" />
          </div>
          <span>更多交易明细，请前往APP查看</span>
        </div>
      </div>
    </div>

    <!-- 交易状态 1-申请中，2-确认中，3-申请成功，4-申请失败 -->
    <!-- <div
      v-if="
        findData.orderStatus == '1' ||
          findData.orderStatus == '3' ||
          findData.orderStatus == '4'
      "
      class="bottom"
    >
      <div class="confirm" @click="back">返回交易记录</div>
    </div>-->
    <!-- <div
      v-if="(findData.orderType === '1' || findData.orderType === '5') &&findData.orderStatus == '2'"
      class="bottom"
    >
      <div class="confirm" @click="confirm">撤回订单</div>
      <span class="back" @click="back">返回</span>
    </div>
    <div v-else class="bottom">
      <div class="confirm" @click="back">返回交易记录</div>
    </div> -->

    <models :showmodel="showModel" @confirms="confirms" @cancels="cancels">
      <div class="models">
        <div class="top">确认撤回订单吗？</div>
        <div class="middle">
          <span>T+1</span>到账后可用
        </div>
        <div class="bm">以银行到账时间为准</div>

        <div v-if="showPwd" class="pwd-content">
          <div class="pwd">请输入交易密码</div>
          <password-input v-model="password" />
        </div>
      </div>
    </models>
  </div>
</template>

<script>
import {
  orderDetail,
  cancelOrder,
  getOrderRecords
} from '@/api/mine/tradeRecord'
import models from '@/components/Models'
import passwordInput from '@/components/PasswordInput'

export default {
  name: 'TradeDetail',
  components: {
    models,
    passwordInput
  },
  data() {
    return {
      loading: false,
      showPwd: false,
      showModel: false,
      active: 1,
      // 交易状态
      orderStatusDic: {},
      //交易类型
      orderTypeDic: {},
      //买入方式， 前端收费，后端收费
      shareTypeDic: {},
      // 详情
      findData: {},
      // 交易状态
      statusTitle: '',
      // 买入方式
      buyMode: '',
      processTitle: {
        process: '',
        result: ''
      },
      // 密码
      password: '',
      accs:null,
      // 订单状态记录
      orderRecordsData: []
    }
  },
  created() {
    this.getDeatail()
    this.getOrderRecords()
    //交易状态
    this.getDictionaries('order_status', 'orderStatusDic')
    //交易类型
    this.getDictionaries('order_type', 'orderTypeDic')
    //买入方式
    this.getDictionaries('prod_fund_share_type', 'shareTypeDic')
  },
  methods: {
    //字典查询
    getDictionaries(val, option) {
      this.getDicts(val).then((res) => {
        if (res.code === 200) {
          console.log('asssssssss',res)
          res.data.forEach((item) => {
            // this[option][item.dictValue] = item.dictLabel
            this.$set(this[option], item.dictValue, item.dictLabel)
          })
        }
      })
    },
    getDeatail() {
      orderDetail({
        orderId: this.$route.query.id
      }).then((r) => {
        this.findData = r.data
        this.accs=this.findData.openBank+"(尾号"+this.findData.bankAccount.slice(-4)+")"

        // 有现金宝标识则买入方式为现金宝，没有则M,P银行卡，K线下大额转账
        if (this.findData.xjbFlag == '1') {
          this.buyMode == '现金宝'
        } else {
          this.buyWay(this.findData.capitalMode)
        }
        // this.statusWay(this.findData.orderStatus)
      })
    },
    // 获取订单状态记录
    getOrderRecords() {
      getOrderRecords({
        orderId: this.$route.query.id
      }).then((res) => {
        if (res.code === 200) {
          this.orderRecordsData = res.data
          this.active = res.data.length
        }
      })
    },
    buyWay(val) {
      switch (val) {
        case 'M':
        case 'P':
          this.buyMode = '银行卡'
          break
        case 'K':
          this.buyMode = '线下转账'
          break
        default:
          '--'
      }
    },
    // 交易状态
    statusWay(val) {
      switch (val) {
        case '1':
          // this.statusTitle = '已付款'
          this.processTitle.process = '确认中'
          this.processTitle.result = '成功'
          break
        case '2':
          // this.statusTitle = '已付款'
          this.processTitle.process = '已付款'
          this.processTitle.result = '成功'
          break
        case '3':
          // this.statusTitle = '交易成功'
          this.processTitle.process = '已付款'
          this.processTitle.result = '成功'
          break
        case '4':
          // this.statusTitle = '交易失败'
          this.processTitle.process = '付款失败'
          this.processTitle.result = '交易失败'
          break
        default:
          '--'
      }
    },
    //确认，撤回订单打开弹窗
    confirm() {
      this.showModel = true
      this.showPwd = false
    },
    // 返回
    back() {
      this.$router.push({name:"TransactionRecord"})
    },
    // 弹窗确认，取消订单
    confirms() {
      if (this.showPwd) {
        if (!this.password) {
          this.$message({
            message: '请输入交易密码！',
            type: 'warning'
          })
          return
        }
        this.showModel = false
        this.loading = true
        const { allotNo, orderId, tradeAcco } = this.findData
        cancelOrder({
          original_appno: allotNo,
          orderId,
          trade_acco: tradeAcco,
          tradePassword: this.password
        })
          .then((res) => {
            if (res.code === 200) {
              this.showPwd = false
              this.loading = false
              // setTimeout(() => {
              // this.showPwd = false
              this.getDeatail()
              // }, 2000)
              this.$message({
                message: '撤回订单成功！',
                type: 'success'
              })
            }
          })
          .catch((err) => {
            this.showPwd = false
            this.loading = false
            this.$message({
              message: err,
              type: 'warning'
            })
            console.log('err', err)
          })
      } else {
        this.showPwd = true
      }
    },
    // 弹窗取消
    cancels() {
      this.showModel = false
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 40px;
  padding-bottom: 40px;
  background: #fff;

  // 标题
  .trade-title {
    padding: 40px;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #1f1f1f;
    cursor: pointer;

    .icon-a-fundmarkect-back {
      margin-right: 14px;
      color: #bdc0cb;
    }
  }

  .detail-total {
    margin: 0 40px;
    border-radius: 2px;
    border: 5px solid rgba(255, 245, 234, 0.5);

    // 顶部交易状态
    .trade-status {
      justify-content: space-between;
      padding: 14px 20px 14px 24px;
      background: rgba(255, 245, 234, 0.5);
      color: #ce9b63;
      border-radius: 2px;

      div:nth-child(1) {
        font-weight: bold;
      }
    }

    // 订单已付款
    .status-success {
      color: #eabf96;
    }

    // 交易内容
    .trade-content {
      padding: 40px 24px;
    }
  }

  .info {
    margin-top: 52px;
  }

  // 内容标题
  .smtital {
    margin-top: 20px;
    padding: 12px;
    background: #f3f4f6;
    font-size: 12px;
    line-height: 12px;
    color: #8691a8;
  }
  .table-list {
    border: 1px solid #f3f4f6;
  }

  // 表格内标题
  .table-title {
    padding: 17px 24px 17px 0;
    border-right: 1px solid #f3f4f6;
    width: 200px;
    font-weight: 290;
    text-align: right;
    font-size: 12px;
    line-height: 15px;
    color: #1f1f1f;
  }
  // 表格详情
  .table-detail {
    flex: 1;
    padding: 16px 0 16px 24px;
  }

  // 底部提示
  .tips {
    margin-top: 30px;
    color: #ce9b63;
    .icon {
      margin-right: 12px;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff5ea;
      border-radius: 50%;
    }
    .icon-mine-tip {
      font-size: 15px;
    }
  }

  // 底部返回按钮
  .bottom {
    margin-top: 60px;
    text-align: center;
    .confirm {
      display: inline-block;
      color: #b88141;
      background: linear-gradient(109.88deg, #f7d9b7 -3.02%, #eabf96 98.27%);
      line-height: 16px;
      border-radius: 2px;
      padding: 16px 88px;
      font-size: 16px;
      cursor: pointer;
    }
    .back {
      margin-left: 20px;
      font-size: 16px;
      line-height: 16px;
      color: #ce9b63;
      cursor: pointer;
    }
  }

  // 确认弹窗
  .models {
    .top {
      font-weight: bold;
      font-size: 19px;
      line-height: 24px;
      color: #000000;
    }
    .middle {
      margin-top: 24px;
      margin-bottom: 16px;
      font-weight: 290;
      font-size: 16px;
      line-height: 21px;
      span {
        color: #e45247;
      }
    }
    .bm {
      font-size: 14px;
      line-height: 20px;
      color: rgba(21, 25, 32, 0.5);
    }
    .pwd-content {
      margin-top: 16px;

      .pwd {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 20px;
        color: rgba(21, 25, 32, 0.5);
      }
    }
  }

  // 步骤条
  .icon-check {
    // color: #eabf96;
  }
  .icon-main {
    font-size: 50px;
  }
  .icon-err {
    color: #e45247;
  }
  // 步骤描述
  .describe {
    margin-top: 13px;
    flex-direction: column;

    .subtitle1 {
      display: inline-block;
      font-size: 14px;
      line-height: 14px;
      color: #1f1f1f;
      font-weight: normal;
    }

    //失败和撤回
    .failed {
      color: #e45247;
      font-weight: bold;
    }
  }
  // 日期
  .date {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    color: #c8c6c0;
  }
  .deactive {
    // color: #bdc0cb;
  }
  // 步骤条样式

  /deep/ .el-step__icon.is-text {
    border: none;
  }
  /deep/ .el-step__title {
    line-height: 24px;
  }
  .step1 {
    /deep/ .el-step__line {
      background-color: #eabf96;
    }
  }
  .step2 {
    /deep/ .el-step__line {
      background-color: #fff5ea;
    }
  }
  /deep/ .el-step__head.is-wait {
    color: #f6f5f3;
  }
  /deep/ .el-step__head.is-process {
    color: #f6f5f3;
  }
  /deep/ .el-step__head.is-finish {
    color: #eabf96;
    border-color: #eabf96;
  }
}
</style>
